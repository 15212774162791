import React from 'react';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBadge,
  MDBRow,
  MDBCol,
  MDBBtn,
} from 'mdb-react-ui-kit';

import Modal from 'react-bootstrap/Modal'

import EikenLogo from "./EikenLogo"
import EikenTrademarkNote from './EikenTrademarkNote';
import QuestionTypeName from './english/QuestionTypeName';

function MenuContent(props) {
  const handleSelectMenu = async (e) => {
    e.preventDefault()
    const handler = props.onSelectMenu
    if (handler) {
      const grade = e.currentTarget.getAttribute('grade')
      const type = e.currentTarget.getAttribute('type')
      handler(grade, type)
    }
  }

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol sm='6'>
          <MDBCard alignment='center my-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <EikenLogo grade="G15"/>
              </MDBCardTitle>
              <div>
                <MDBBtn size="lg" className='mt-1 w-75 green-button' onClick={handleSelectMenu} grade='G15' type='opinion'>
                  {QuestionTypeName('opinion')}
                </MDBBtn>
              </div>
              <div className='position-relative'>
                <MDBBtn size="lg" className='mt-3 w-75 green-button' onClick={handleSelectMenu} grade='G15' type='summary'>
                  {QuestionTypeName('summary')}
                </MDBBtn>
                <MDBBadge color='danger' light pill className='mt-4 position-absolute translate-middle new-type-badge'>
                  新形式
                </MDBBadge>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol sm='6'>
          <MDBCard alignment='center my-3'>
            <MDBCardBody>
              <MDBCardTitle>
              <EikenLogo grade="G20"/>
              </MDBCardTitle>
              <div>
                <MDBBtn size="lg" className='mt-1 w-75 green-button' onClick={handleSelectMenu} grade='G20' type='opinion'>
                  {QuestionTypeName('opinion')}
                </MDBBtn>
              </div>
              <div className='position-relative'>
                <MDBBtn size="lg" className='mt-3 w-75 green-button' onClick={handleSelectMenu} grade='G20' type='summary'>
                  {QuestionTypeName('summary')}
                </MDBBtn>
                <MDBBadge color='danger' light pill className='mt-4 position-absolute translate-middle new-type-badge'>
                  新形式
                </MDBBadge>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow center>
        <MDBCol sm='6'>
          <MDBCard alignment='center my-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <EikenLogo grade="G25"/>
              </MDBCardTitle>
              <div>
                <MDBBtn size="lg" className='mt-1 w-75 green-button' onClick={handleSelectMenu} grade='G25' type='opinion'>
                  {QuestionTypeName('opinion')}
                  
                </MDBBtn>
              </div>
              <div className='position-relative'>
                <MDBBtn size="lg" className='mt-3 w-75 green-button' onClick={handleSelectMenu} grade='G25' type='email'>
                  {QuestionTypeName('email')}
                </MDBBtn>
                <MDBBadge color='danger' light pill className='mt-4 position-absolute translate-middle new-type-badge'>
                  新形式
                </MDBBadge>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol sm='6'>
          <MDBCard alignment='center my-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <EikenLogo grade="G30"/>
              </MDBCardTitle>
              <div>
              <MDBBtn size="lg" className='mt-1 w-75 green-button' onClick={handleSelectMenu} grade='G30' type='opinion'>
                {QuestionTypeName('opinion')}
              </MDBBtn>
              </div>
              <div className='position-relative'>
                <MDBBtn size="lg" className='mt-3 w-75 green-button' onClick={handleSelectMenu} grade='G30' type='email'>
                  {QuestionTypeName('email')}
                </MDBBtn>
                <MDBBadge color='danger' light pill className='mt-4 position-absolute translate-middle new-type-badge'>
                  新形式
                </MDBBadge>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow className="py-3">
        <MDBCol>
          <EikenTrademarkNote/>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

function MenuDialog(props) {

  return (
    <Modal
      {...props}
      size="lg"
      fullscreen="md-down"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <MDBContainer className="ms-4">
          {props.isLoggedIn ? 
            <MDBRow center className="h2">練習する問題形式を選んでください</MDBRow>
            :
            <div>
              <MDBRow center className="h2">練習したい級の問題形式を選ぼう</MDBRow>
              <MDBRow center>
                まずはサンプル問題で何度も試して、<br/>
                レベルアップを実感してみましょう！
              </MDBRow>
            </div>
          }
        </MDBContainer>
      </Modal.Header>
      <Modal.Body>
        <MenuContent onSelectMenu={props.onSelectMenu}/>
      </Modal.Body>
    </Modal>
  );
}

export {MenuContent, MenuDialog}
