import { Statement } from './SampleData';
import { Card, Stack } from 'react-bootstrap';
import { MDBIcon, MDBBadge } from 'mdb-react-ui-kit';
import parse from 'html-react-parser';
import {OpinionAssignment, OpinionAssignmentSupplement} from './OpinionAssignment';
import {EMailAssignment, EMailAssignmentSupplement} from './EMailAssignment';
import {SummaryAssignment, SummaryAssignmentSupplement} from './SummaryAssignment';

// const maxLengths = {
//   "G15": {"opinion": 1050},
//   "G20": {"opnion": 700},
//   "G25": {"opnion": 420},
//   "G30": {"opnion": 245},
//   "G30": {"email": 245},
// }
// maxLengths is not taking effect for now

export function AssignmentSupplementCard(props) {
  if (props.assignment == null) {
    return null;
  }
  let type_specific_supplement_info = null;
  if (props.assignment.question.type == 'opinion') {
    type_specific_supplement_info = <OpinionAssignmentSupplement assignment={props.assignment} />
  }
  else if (props.assignment.question.type == 'email') {
    type_specific_supplement_info = <EMailAssignmentSupplement assignment={props.assignment} />
  }
  else if (props.assignment.question.type =='summary') {
    type_specific_supplement_info = <SummaryAssignmentSupplement assignment={props.assignment} />
  }
  else {
    throw new Error(`unsupported type: ${props.assignment.question.type}`);
  }
  if (type_specific_supplement_info == null){
    return null;
  }
  return (
    <Card className='px-2 px-md-0'>
      {type_specific_supplement_info}
    </Card>
  )
}

export function AssignmentCard(props) {
  if (props.assignment == null) {
    return null;
  }

  const statementData = Statement[props.assignment.question.grade][props.assignment.question.type]
  let instruction_list = statementData['statement'].map((statement, i) => {
    return <li key={i}>{parse(statement)}</li>
  })

  let type_specific_info = null;
  if (props.assignment.question.type == 'opinion') {
    type_specific_info = <OpinionAssignment assignment={props.assignment} />
  }
  else if (props.assignment.question.type == 'email') {
    type_specific_info = <EMailAssignment assignment={props.assignment} />
  }
  else if (props.assignment.question.type == 'summary') {
    type_specific_info = <SummaryAssignment assignment={props.assignment} />
  }
  else {
    throw new Error(`unsupported type: ${props.assignment.question.type}`);
  }

  return (
    <Card className='px-md-0'>
      <Card.Header className="fw-bold px-md-4 px-1 py-md-2 py-1">
        <div className="d-flex align-items-center justify-content-between">
          <div className='card-headder-margin'>
            <MDBIcon fas icon="chalkboard-teacher me-2 align-middle" />
            <span className="align-middle fw-bold">設問</span>
          </div>
          <div className='ms-auto'>
            <MDBBadge color='light' size='sm' className='supposed-time-badge'>
              解答時間の目安：{statementData['supposed_min']}分
            </MDBBadge >
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-md-4 px-1 py-3 lh-lg">
        <div>
          <div>
            <ul>
              {instruction_list}
            </ul>
          </div>
          {type_specific_info}
        </div>
      </Card.Body>
    </Card>
  )
}