import React, { Component } from "react";
import { Container, Row, Card, Alert } from 'react-bootstrap';
import { MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import UguisTextEditor from "./UguisTextEditor"
import { Statement } from './SampleData';
import Dialog from 'react-bootstrap-dialog'

export default class EssayView extends React.Component {
  constructor(props) {
    super(props);
    this.viewRef = React.createRef()
  }

  getInputValue() {
    return this.viewRef.current?.getInputValue()
  }

  essaySaved() {
    return this.viewRef.current?.essaySaved()
  }

  isEssaySaved() {
    return this.viewRef.current?.isEssaySaved()
  }

  render() {
    if (this.props.assignment == null) {
      return null;
    }
    if (this.props.assignment.question.type == 'opinion') {
      return (
        <OpinionEssayView
          ref={this.viewRef}
          assignment={this.props.assignment}
          keyboardOff={this.props.keyboardOff}
          onEditorFocused={this.props.onEditorFocused}/>
      )
    }
    else if (this.props.assignment.question.type == 'email') {
      return (
        <EMailEssayView
          ref={this.viewRef}
          assignment={this.props.assignment}
          keyboardOff={this.props.keyboardOff}
          onEditorFocused={this.props.onEditorFocused}/>
        )
    }
    else if (this.props.assignment.question.type =='summary') {
      return (
        <SummaryEssayView
          ref={this.viewRef}
          assignment={this.props.assignment}
          keyboardOff={this.props.keyboardOff}
          onEditorFocused={this.props.onEditorFocused}/>
      )
    }
  }
}

class AbstractEssayView extends React.Component {
  dialog;
  constructor(props) {
    super(props);
    this.editorRef = React.createRef()

    this.state = {
      essayChanged: false,
      assignment: props.assignment,
      seed: Math.random()
    }
  }

  handleEssayChange = (value) => {
    // console.log('handleEssayChange')
    this.setState(prevState => ({
      ...prevState,
      essayChanged: true
    }))
  }

  handleClearEssay = async(e) => {
    this.dialog.show({
      title: '書かれている解答をクリアしますか？',
      body: '解答をクリアすると元に戻せません。',
      actions: [
        Dialog.Action(
          'キャンセル',
          () => this.dialog.hide(),
          'btn-secondary'
        ),
        Dialog.Action(
          'クリアする',
          async () => {
            this.setState(prevState => ({
              ...prevState,
              assignment: {
                ...prevState.assignment,
                essay: null,
                feedback: null,
              },
              seed: Math.random()
            }))
          },
          'btn-danger'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  essaySaved() {
    this.setState(prevState => ({
      ...prevState,
      essayChanged: false
    }))
  }

  isEssaySaved() {
    return !this.state.essayChanged
  }

  getInputValue() {
    // console.log('get input value')
    return this.editorRef.current?.getInputValue()
  }

  getEssayNotSavedWarning() {
    if (!this.state.essayChanged) {
      return null
    }
    return (
        <Alert key='warning' variant='warning' className='py-1 px-3 m-1'>
          <small>
            書きかけの文章は保存されていません。AI採点して文章を保存しましょう。
          </small>
        </Alert>
      )
  }

}


class OpinionEssayView extends AbstractEssayView {
  render() {
    return (
      <Card>
        <Card.Header className="fw-bold px-md-4 px-1 py-md-2 py-1">
          <div className="d-flex align-items-center justify-content-between">
            <div className='card-headder-margin'>
              <MDBIcon fas icon="pen me-2 align-middle" />
              <span className="align-middle fw-bold">あなたの解答</span>
            </div>
            <MDBBtn size="sm" fluid className={`gradient-bubble m-1 outline`} rounded onClick={this.handleClearEssay}>解答をクリア</MDBBtn>
          </div>
        </Card.Header>
        <Card.Body className='p-md-4 p-1'>
          <div className="px-3 py-2 border border-dark border-2 rounded">
          <UguisTextEditor
            key={this.state.seed}
            ref={this.editorRef}
            feedback={this.state.assignment.feedback}
            essay={this.state.assignment.essay}
            question_data={this.state.assignment.question}
            placeholder='解答を英文で入力しましょう'
            onEssayChange={this.handleEssayChange}
            keyboardOff={this.props.keyboardOff}
            onFocused={this.props.onEditorFocused}
          />
          </div>
          {this.getEssayNotSavedWarning()}
        </Card.Body>
        <Dialog ref={(el) => { this.dialog = el }} />
      </Card>
    )
  }
}

class EMailEssayView extends AbstractEssayView {
  render() {
    let statement_data = Statement[this.state.assignment.question.grade]['email']

    return (
      <Card>
        <Card.Header className="fw-bold px-md-4 px-1 py-md-2 py-1">
          <div className="d-flex align-items-center justify-content-between">
            <div className='card-headder-margin'>
              <MDBIcon far icon="envelope me-2 align-middle"/>
              <span className="align-middle">Re: {this.state.assignment.title}</span>
            </div>
            <MDBBtn size="sm" fluid className={`gradient-bubble m-1 outline`} rounded onClick={this.handleClearEssay}>解答をクリア</MDBBtn>
          </div>
        </Card.Header>
        <Card.Body className='p-md-4 p-1'>
          <Card.Title>
            <img src="profile.png" height="30" alt="avatar" loading="lazy" className='rounded-circle me-2'/>
            <span className="h6">me</span>
          </Card.Title>
          <div className="email email-reply">
            <div>{this.state.assignment.question.greeting}</div>
            <div>{statement_data['content_start']}</div>
          </div>
          <Row>
            <div className="px-3 py-1 me-4 border border-dark border-2 rounded">
              <UguisTextEditor
                key={this.state.seed}
                ref={this.editorRef}
                feedback={this.state.assignment.feedback}
                essay={this.state.assignment.essay}
                question_data={this.state.assignment.question}
                placeholder='解答を英文で入力しましょう'
                onEssayChange={this.handleEssayChange}
                keyboardOff={this.props.keyboardOff}
                onFocused={this.props.onEditorFocused}
              />
            </div>
          </Row>
          <div className="email email-reply">{this.state.assignment.question.closing}</div>
          {this.getEssayNotSavedWarning()}
        </Card.Body>
        <Dialog ref={(el) => { this.dialog = el }} />
      </Card>
    )
  }
}

class SummaryEssayView extends AbstractEssayView {
  render() {
    return (
      <Card>
        <Card.Header className="fw-bold px-md-4 px-1 py-md-2 py-1">
          <div className="d-flex align-items-center justify-content-between">
            <div className='card-headder-margin'>
              <MDBIcon fas icon="pen me-2 align-middle" />
              <span className="align-middle fw-bold">あなたの解答</span>
            </div>
            <MDBBtn size="sm" fluid className={`gradient-bubble m-1 outline`} rounded onClick={this.handleClearEssay}>解答をクリア</MDBBtn>
          </div>
        </Card.Header>
        <Card.Body className='px-md-3 py-3 py-md-4 px-0'>
          <div className="p-1 p-md-3 border border-dark border-2 rounded">
          <UguisTextEditor
            key={this.state.seed}
            ref={this.editorRef}
            feedback={this.state.assignment.feedback}
            essay={this.state.assignment.essay}
            question_data={this.state.assignment.question}
            placeholder='解答を英文で入力しましょう'
            onEssayChange={this.handleEssayChange}
            keyboardOff={this.props.keyboardOff}
            onFocused={this.props.onEditorFocused}
          />
          </div>
          {this.getEssayNotSavedWarning()}
        </Card.Body>
        <Dialog ref={(el) => { this.dialog = el }} />
      </Card>
    )
  }
}
