import { Statement } from './SampleData';
import { MDBIcon, MDBBadge } from 'mdb-react-ui-kit';
import { Row, Card, Stack} from 'react-bootstrap';
import parse from 'html-react-parser';

export function EMailAssignmentSupplement(props) {
  const question = props.assignment.question
  let friends_email = question.email
  if (question.topic_char_start_index != null && question.topic_char_end_index != null) {
    friends_email = (
      friends_email.substr(0, question.topic_char_start_index)
      + '<u>'
      + friends_email.substr(question.topic_char_start_index, question.topic_char_end_index - question.topic_char_start_index)
      + '</u>'
      + friends_email.substr(question.topic_char_end_index)
    )
  }
  friends_email = friends_email.split('\n').map((line, i) => {
    return <div>{parse(line)}</div>
  })

  return (
    <>
      <Card.Header className="fw-bold fw-bold px-md-4 px-1 py-md-2 py-1">
        <div className='card-headder-margin'>
          <MDBIcon far icon="envelope me-2 align-middle" />
          <span className="align-middle">{props.assignment.title}</span>
        </div>
      </Card.Header>
      <Card.Body className="p-md-4 px-1 py-2">
        <Card.Title>
          <img src="male.png" height="30" alt="avatar" loading="lazy" className='rounded-circle me-2' />
          <span className="h6">{props.assignment.question.sender.name}</span>
        </Card.Title>
        <div className="email ms-1">
          {friends_email}
          <div>Your friend,</div>
          <div>{props.assignment.question.sender.name}</div>
        </div>
      </Card.Body>
    </>
  );
}

export function EMailAssignment(props) {
  const question = props.assignment.question

  let question_section = null
  if (question.grade == 'G30') {
    question_section = <div>
      質問
      <ul>
        <li>{question.wh_question0}</li>
        <li>{question.wh_question1}</li>
      </ul>
    </div>
  }

  return (
    <>
      {question_section}
    </>
  )
}