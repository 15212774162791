import { Statement } from './SampleData';
import { MDBIcon, MDBBadge } from 'mdb-react-ui-kit';
import { Row, Card, Stack } from 'react-bootstrap';
import parse from 'html-react-parser';

export function SummaryAssignmentSupplement(props) {
  const question = props.assignment.question

  let statement_data = Statement[question.grade]['summary']

  const subject_title = statement_data['subject_title'];
  const articles = question.passage.split('\n').map((article, i) => {
    return <p key={i}>{article}</p>
  })

  return (
    <>
      <Card.Header className="fw-bold fw-bold px-md-4 px-1 py-md-2 py-1">
        <div className='card-headder-margin'>
          <MDBIcon far icon="file-alt me-2 align-middle" />
          <span className="align-middle">{subject_title}</span>
        </div>
      </Card.Header>
      <Card.Body className="p-md-4 px-1 py-2">
        <div className="article ms-1">
          {articles}
        </div>
      </Card.Body>
    </>
  );
}

export function SummaryAssignment(props) {

  return null;
}