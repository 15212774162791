import { Statement } from './SampleData';
import { Row, Card, Stack } from 'react-bootstrap';
import { MDBIcon, MDBBadge } from 'mdb-react-ui-kit';
import parse from 'html-react-parser';

export function OpinionAssignmentSupplement(props){
  return null;
}

export function OpinionAssignment(props) {
  const assignment = props.assignment;
  let points = [];
  if (assignment.question.keywords != null){
    points = assignment.question.keywords.map(
      (point, index) => {
        if (point == "") {
          return null;
        }
        else {
          return <li>{point}</li>
        }
      }
    ).filter(x => x != null);
  }
  let points_list = null
  if (points.length != 0) {
    points_list = <div className="mt-3">
      <div>POINTS</div>
      <ul>{points}</ul>
    </div>
  }
  const statementData = Statement[assignment.question.grade][assignment.question.type]
  return (
    <>
      <div>{statementData['subject_title']}</div>
      <div>{assignment.question.question}</div>
      {points_list}
    </>
  )
}
