import { getSession, isSignedIn } from './Auth/auth'
import { Question, OpinionQuestion, EmailQuestion, Assignment } from "./english/model/Assignment";

async function callAuthenticatedAPI(method, url, body=null) {
  const session = await getSession()
  const accessToken = session.tokens.accessToken.toString();
  return await callAPI(method, url, {'Authorization': `Bearer ${accessToken}`}, body)
}

async function callAPI(method, url, headers={}, body=null) {
  const reqOptions = {
    method: method,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: body
  };
  const response = await fetch(url, reqOptions);
  const jsonData = await response.json()
  return { json: jsonData, status: response.status }
}

export async function getUserProfile() {
  const response = await callAuthenticatedAPI('GET', '/api/profile')
  if (response.status == 200) {
    return response.json
  }
  else{
    return null
  }
}

export async function updateAcceptedTosVersion(accepted_tos_version) {
  const response = await callAuthenticatedAPI('PUT', '/api/profile/accepted_tos_version', JSON.stringify({
    "accepted_tos_version": accepted_tos_version,
  }))

  if (response.status == 200) {
    return response.json
  }
  else{
    return false
  }
}

export async function deleteUserAccount(id) {
  const response = await callAuthenticatedAPI('DELETE', `/api/profile`)
  if (response.status == 200) {
    return true
  }
  else {
    return false
  }
}

export async function getUserAssignments() {
  const response = await callAuthenticatedAPI('GET', '/api/assignments')
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function getUserAssignment(id) {
  const response = await callAuthenticatedAPI('GET', `/api/assignments/${id}`)
  if (response.status != 200) {
    return null
  }

  const assignment = response.json

  return {
    "id": assignment.id,
    "grade": assignment.grade,
    "type": assignment.type,
    "title": assignment.title,
    "score": assignment.score,
    "modified_at": assignment.modified_at,
    "assignment": Assignment.fromJSON(assignment.assignment_data)
  }
}

export async function deleteUserAssignment(id) {
  const response = await callAuthenticatedAPI('DELETE', `/api/assignments/${id}`)
  if (response.status == 200) {
    return true
  }
  else {
    return false
  }
}

export async function createUserAssignment(assignment) {
  const response = await callAuthenticatedAPI('POST', `/api/assignments/`, JSON.stringify({
    "grade": assignment.question.grade,
    "type": assignment.question.type,
    "title": assignment.title,
    "score": assignment.score,
    "assignment": assignment
  }))
  if (response.status == 201) {
    const id = response.json
    return id
  }
  else {
    return null
  }
}

export async function updateUserAssignment(id, assignment) {
  await callAuthenticatedAPI('PUT', `/api/assignments/${id}`, JSON.stringify({
    "grade": assignment.question.grade,
    "type": assignment.question.type,
    "title": assignment.title,
    "score": assignment.score,
    "assignment": assignment
  }))
  return true
}

export async function scoreAssignment(assignment) {
  const isLoggedIn = await isSignedIn();
  let response;

  if (isLoggedIn) {
    response = await callAuthenticatedAPI('POST', `/api/score/english`, JSON.stringify(assignment));
  } else {
    response = await callAPI('POST', `/api/score/english`, {}, JSON.stringify(assignment));
  }

  if (response.status == 200) {
    return response.json;
  } else {
    return null;
  }
}

export async function generateQuestion(grade, type, topic){
  const response = await callAuthenticatedAPI(
    'POST',
    `/api/generate/english`,
    JSON.stringify({'grade': grade, 'type': type, 'topic': topic}))
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function getSystemConfig() {
  const response = await callAPI('GET', '/api/system_config')
  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}

export async function getCorrection(content, style, lang) {
  let response
  let request_data = {
    content: content,
    style: style,
    lang: lang
  }
  const isLoggedIn = await isSignedIn()
  if (isLoggedIn) {
    response = await callAuthenticatedAPI('POST', `/api/correction`, JSON.stringify(request_data))
  }
  else {
    response = await callAPI('POST', `/api/correction`, {}, JSON.stringify(request_data))
  }

  if (response.status == 200) {
    return response.json
  }
  else {
    return null
  }
}