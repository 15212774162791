function QuestionTypeName(type) {
    switch (type) {
        case 'opinion':
            return '意見論述問題'
        case 'email':
            return 'Eメール問題'
        case 'summary':
            return '要約問題'
        case null:
            // this case may be used from client\src\DetailedMenu.js
            return null
        default:
            throw new Error(`unsupported ${type}`)
    }
}

export default QuestionTypeName;