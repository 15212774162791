import HeaderedScore from './HeaderedScore';
import {Container, Row, Col, Card, OverlayTrigger, Tooltip, Stack, Alert } from 'react-bootstrap';
import {MDBIcon} from 'mdb-react-ui-kit';

function SummaryScore(props) {
  const feedback = props.feedback;
  let coherence_score
  if (props.type == "opinion" || props.type == "summary") {
    coherence_score = feedback.coherence.sys_coherence
  }
  else if (props.type == "email") {
    // email has no coherence score
    coherence_score = feedback.content.sys_content
  }
  else {
    throw new Error(`unsupported ${props.type}`);
  }

  const score = Math.floor((feedback.content.sys_content + coherence_score + feedback.grammar_vocabulary.sys_grammar + feedback.grammar_vocabulary.sys_vocabulary) / 4) + 1
  const max_score = props.max_score;
  if (feedback == null) {
    return false;
  }

  const size = props.size ?? 'lg';

  if (size == 'lg') {
    return (
      <Card>
        <Card.Header className="fw-bold py-md-2 py-1">
          <div className='card-headder-margin'> 
            <MDBIcon far icon="star me-2 align-middle" />
            <span className="align-middle fw-bold">採点結果</span>
          </div>
        </Card.Header>
        <Card.Body>
          <Container className="mt-3">
            <Row>
              <HeaderedScore
                header="総合評価："
                score={score}
                max_score={max_score}
              />
            </Row>
            <Row className="mt-3">
              <div>あなたの解答は<MDBIcon fas icon="star" style={{color: "orange"}}/>{score}レベルです。</div>
              { score < max_score &&
                <div>苦手とするポイントを改善して<MDBIcon fas icon="star" style={{color: "orange"}}/>{max_score}を目指しましょう。</div>
              }
              { score == max_score &&
                <div>この級の最高得点です！</div>
              }
            </Row>
          </Container>
        </Card.Body>
      </Card>
    )
  }
  else if (size == 'sm') {
    return (
      <HeaderedScore
        header="総合評価："
        score={score}
        max_score={max_score}
        size={size}
      />
    )
  }
}

export default SummaryScore;
